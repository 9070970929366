<template>
    <!--begin::Dashboard-->
    <div class="p-5 container">
      <div class="row mb-5" style="padding: 2vh;">
        <div class="col-12 col-lg-5">
          <img
            v-if="listData"
            :src="
              listData && listData.examRoomProfile.examProfile.thumbnailsPath
                ? imageUrl + listData.examRoomProfile.examProfile.thumbnailsPath
                : 'media/image-app/no-image.svg'
            "
            alt=""
            @error="setAltImg"
            class="w-100 mb-3"
            style="border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              object-fit: cover;
              height: 350px;border-radius: 20px;
              border: 1px solid #dbdbdb;"
          />
          <div>
            <p class="text-category" style="font-weight: 700;color: #333333;">
              ระบบจะเริ่มสอบในเวลา
            </p>
            <p
              style="font-weight: 400;font-size:24px;
                  color: #DA595A;"
            >
              <i class="fa fa-clock fs-1" style="color: #DA595A;"></i>
              {{ countDownTime }}
            </p>
            <div class="fv-row my-5">
              <label class="form-check form-check-custom form-check-solid">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="toc"
                  value="true"
                  v-model="toc"
                  :disabled="!examReady ||  listData.status == 2"
                />
           
                <span class="form-check-label fw-bold text-gray-700 " style="font-size:16px">
                  ฉันยอมรับ
                  <a href="#" class="ms-1 link-primary"
                    >เข้าใจและยอมรับเงื่อนไขการทำแบบทดสอบ</a
                  >.
                </span>
              </label>
              <!-- <div v-if="!modelRegister.toc" class="text-danger">
            กรุณาระบุ
          </div> -->
            </div>
            <button
              type="button"
              @click="register()"
              :disabled="!examReady ||  listData.status == 2 || !toc"
              class="btn btn  btn-learn w-100"
            >
              เริ่มทำแบบทดสอบ
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <p style="font-weight: 700;color: #333333;font-size:24px">
            {{ listData ? listData.examRoomProfile.examProfile.title : "" }}
          </p>
          <p style="font-weight: 700;color: #333333;font-size:16px">
            รหัสแบบทดสอบ : <span style="color: #DA595A;">{{
              listData ? listData.examRoomProfile.examProfile.examCode : ""
            }}</span>
          </p>
           <p style="font-weight: 700;color: #333333;font-size:16px">
            เลขห้องสอบ : <span style="color: #DA595A;">{{
              listData ? listData.examRoomProfile.examRoomCode : ""
            }}</span>
          </p>
          <p style="font-weight: 700;color: #333333;font-size:16px">
            รอบที่สอบ :
            <span style="color: #DA595A;"
              >{{
                listData ? formatDateTime(listData.examRoomProfile.startDate) : ""
              }}
              -
              {{
                listData ? formatDateTime(listData.examRoomProfile.endDate) : ""
              }}</span
            >
          </p>
          <p style="font-weight: 700;color: #333333;font-size:16px">
            ชื่อศูนย์สอบ :
            <span style="color: #DA595A;">{{
              listData ? listData.examRoomProfile.examCenterProfile.name : ""
            }}</span>
          </p>
          <p style="font-weight: 700;color: #333333;font-size:16px">
            รายละเอียดแบบทดสอบ
          </p>
          <p
            v-html="
              listData ? listData.examRoomProfile.examProfile.description : ''
            "
            style="font-weight: 400;
            font-size:16px;color: #7F7F81;"
          ></p>
          <p style="font-weight: 700;color: #333333;font-size:16px">
            เงื่อนไขการทำแบบทดสอบ
          </p>
          <p
            v-html="listData ? listData.examRoomProfile.examProfile.info : ''"
            style="font-weight: 400;
            font-size:16px;color: #7F7F81;"
          ></p>
          <!-- <p>{{ formatDate(listData ? listData.createDate : "") }}</p> -->
        </div>
      </div>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import axios from "axios";
  import { useRoute, useRouter } from "vue-router";
  const listData = ref();
  
  export default defineComponent({
    name: "exam-detail",
    components: {},
    data() {
      return {
        imageUrl: process.env.VUE_APP_IMG_URL,
        countDown: 0,
        countDownTime: "",
        examReady: false,
        toc:false,
      };
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
       if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        router.go(0);
      }
      onMounted(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getDataList();
      });
      const getDataList = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getExamRoomRegister?examRoomId=" +
              route.params.id +
              "&isDetail=true" +
              "&userId=" + localStorage.getItem("u_id"),
            {
              headers: {
              token: localStorage.getItem("id_token") 
            },
            }
          )
          .then((response) => {
            listData.value = response.data.data.content[0];
            setCurrentPageBreadcrumbs( "แบบฝึกหัด", []);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const register = async () => {
        if (localStorage.getItem("id_token")) {
          localStorage.setItem("reloaded",'1')
       const  getSyllabusRegister = await  axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getSyllabusRegister?syllabusId=" +
              listData.value.examRoomProfile.syllabusId +
              "&userId=" + localStorage.getItem("u_id"),
            {
              headers: {
                token: localStorage.getItem("id_token") 
              },
            }
          )
          // console.log(getSyllabusRegister);
          
          router.push({
            name: "exam-test",
            params: { exrId: listData.value.examRoomProfile.id,
            exId: listData.value.examRoomProfile.examId,
            sbId:  getSyllabusRegister.data.data.content[0].id},
          });
        } else {
          router.push({ name: "sign-in" });
        }
      };
  
      return {
        getDataList,
        listData,
        register,
      };
    },
    methods: {
      setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
      formatDate(date) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
      formatDateTime(date) {
        const event = new Date(date);
        return (
          event.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }) + " น."
        );
      },
      checkCountDown() {
        this.countDown = window.setInterval(() => {
          if (listData.value) {
            const now = new Date().getTime();
            // Find the distance between now and the count down date
            const distance =
              new Date(listData.value.examRoomProfile.startDate).getTime() - now;
  
            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours: any = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes: any = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
            if (hours < 10) hours = "0" + hours;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
  
            // Display the result in the element with id="demo"
            // document.getElementById("demo").innerHTML =
            //   hours + " : " + minutes + " : " + seconds + "";
            this.countDownTime =
              days +
              " วัน " +
              hours +
              " ชั่วโมง " +
              minutes +
              " นาที " +
              seconds +
              " วินาที";
            // If the count down is finished, write some text
            if (distance < 0) {
              this.examReady = true;
              this.countDownTime =
                "0" +
                " วัน " +
                "0" +
                " ชั่วโมง " +
                "0" +
                " นาที " +
                "0" +
                " วินาที";
              clearInterval(this.countDown);
              // document.getElementById("demo").innerHTML = "EXPIRED";
            }
          }
        }, 1000);
      },
    },
    async mounted() {
      this.checkCountDown();
    },
  });
  </script>
  <style>
  .btn-app {
    background: #da595a;
    color: #ffffff;
    border-radius: 100px;
  }
  .btn-app2 {
    background: #323563;
    color: #ffffff;
    border-radius: 100px;
  }
  .btn-register {
    background: #f2a17d;
    color: #ffffff;
    width: 70%;
    border-radius: 21px;
  }
  .btn-learn {
    width: 70%;
    color: #ffffff;
    background: #323563;
    border-radius: 21px;
  }
  </style>
  