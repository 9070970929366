
  import { defineComponent, onMounted, ref } from "vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import axios from "axios";
  import { useRoute, useRouter } from "vue-router";
  const listData = ref();
  
  export default defineComponent({
    name: "exam-detail",
    components: {},
    data() {
      return {
        imageUrl: process.env.VUE_APP_IMG_URL,
        countDown: 0,
        countDownTime: "",
        examReady: false,
        toc:false,
      };
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
       if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        router.go(0);
      }
      onMounted(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getDataList();
      });
      const getDataList = () => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getExamRoomRegister?examRoomId=" +
              route.params.id +
              "&isDetail=true" +
              "&userId=" + localStorage.getItem("u_id"),
            {
              headers: {
              token: localStorage.getItem("id_token") 
            },
            }
          )
          .then((response) => {
            listData.value = response.data.data.content[0];
            setCurrentPageBreadcrumbs( "แบบฝึกหัด", []);
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      const register = async () => {
        if (localStorage.getItem("id_token")) {
          localStorage.setItem("reloaded",'1')
       const  getSyllabusRegister = await  axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getSyllabusRegister?syllabusId=" +
              listData.value.examRoomProfile.syllabusId +
              "&userId=" + localStorage.getItem("u_id"),
            {
              headers: {
                token: localStorage.getItem("id_token") 
              },
            }
          )
          // console.log(getSyllabusRegister);
          
          router.push({
            name: "exam-test",
            params: { exrId: listData.value.examRoomProfile.id,
            exId: listData.value.examRoomProfile.examId,
            sbId:  getSyllabusRegister.data.data.content[0].id},
          });
        } else {
          router.push({ name: "sign-in" });
        }
      };
  
      return {
        getDataList,
        listData,
        register,
      };
    },
    methods: {
      setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
      formatDate(date) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
      formatDateTime(date) {
        const event = new Date(date);
        return (
          event.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }) + " น."
        );
      },
      checkCountDown() {
        this.countDown = window.setInterval(() => {
          if (listData.value) {
            const now = new Date().getTime();
            // Find the distance between now and the count down date
            const distance =
              new Date(listData.value.examRoomProfile.startDate).getTime() - now;
  
            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours: any = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes: any = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
            if (hours < 10) hours = "0" + hours;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
  
            // Display the result in the element with id="demo"
            // document.getElementById("demo").innerHTML =
            //   hours + " : " + minutes + " : " + seconds + "";
            this.countDownTime =
              days +
              " วัน " +
              hours +
              " ชั่วโมง " +
              minutes +
              " นาที " +
              seconds +
              " วินาที";
            // If the count down is finished, write some text
            if (distance < 0) {
              this.examReady = true;
              this.countDownTime =
                "0" +
                " วัน " +
                "0" +
                " ชั่วโมง " +
                "0" +
                " นาที " +
                "0" +
                " วินาที";
              clearInterval(this.countDown);
              // document.getElementById("demo").innerHTML = "EXPIRED";
            }
          }
        }, 1000);
      },
    },
    async mounted() {
      this.checkCountDown();
    },
  });
  